<template>
  <v-container
    id="partnersList"
    fluid
    tag="section">
    <v-row
      align="center"
      justify="center">
      <v-col cols="12">
        <base-material-card
          color="green">
          <template v-slot:heading>
            <h1 class="display-2 font-weight-regular">
              CP > 크롤링 > CSS SELECTOR
            </h1>
            <div class="subtitle-1 font-weight-regular">
              CP 컨텐츠 수집 시 제외할 키워드의 대상 DOM Element를 식별하기 위한 CSS Selector를 관리합니다.
            </div>
          </template>

          <v-card-text>
            <v-row justify="space-between" align="center">
              <v-col cols="2">
              </v-col>
              <v-col cols="2">
                <form @submit.prevent="submitKeyword">
                  <v-text-field
                    type="text"
                    name="keyword"
                    v-model="vModel.keyword"
                    append-icon="mdi-magnify"
                    class="ml-auto pt-0"
                    placeholder="CP명 검색"
                    color="secondary"
                    hide-details
                    single-line />
                </form>
              </v-col>
            </v-row>
          </v-card-text>

          <v-btn small @click="moveRegister()" class="success">신규 등록</v-btn>

          <template v-if="response.list">
            <v-data-table
              :headers="config.headers"
              :items="response.list"
              :items-per-page="response.meta.perPage"
              no-data-text="데이터 없습니다."
              hide-default-footer>

              <template v-slot:item="{ item, index }">
                <tr @click="tableRowClick(item)">
                  <td class="text-center">{{ item.id }}</td>
                  <td class="text-center">{{ getPartnerName(item.partner_name) }}</td>
                  <td class="text-center">{{ cssSelectorTypeToLabel(item.type) }}</td>
                  <td class="text-center"><p class="col-sm-8">{{ item.css_selector }}</p></td>
                </tr>
              </template>
            </v-data-table>
            <v-pagination
              v-model="vModel.page"
              :length="response.meta.lastPage"
              @input="paginationClick"
              :total-visible="10"
              circle
              class="justify-center mt-5"
              next-icon="mdi-menu-right"
              prev-icon="mdi-menu-left"/>
          </template>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import { common } from '@/mixins/common.js'

export default {
  mixins: [ common ],
  created () {
    this.refresh()
  },
  data () {
    return {
      response: {
        list: null,
        meta: null
      },
      vModel: {
        per: 20,
        page: 1,
        keyword: '',
      },
      config: {
        headers: [
          { text: 'No', value: 'id', align: 'center' },
          { text: 'CP명', value: 'partner_id', align: 'center' },
          { text: 'TYPE', value: 'type', align: 'center' },
          { text: 'CSS SELECTOR', value: 'css_selector', align: 'center' },
        ],
        cssSelectorTypes: [
          { text: '섹션', value: 'section' },
          { text: '제목', value: 'title' },
          { text: '본문', value: 'body' },
        ]
      }
    }
  },
  methods: {
    ...mapActions(['xhttp']),
    snack (type, val) {
      this.vModel.errorMessage = val
      this.vModel.snackbarType = type
      this.vModel.snackbar = true
    },
    /* 페이지 로드시 */
    refresh () {
      this.getXHttpParams(true)

      this.cssSelectorList()
    },
    cssSelectorList () {
      let send = this.getXHttpParams(true)

      this.xhttp({
        url: '/partners/crawlings/css_selectors',
        method: 'get',
        params: send
      }).then((response) => {
        if (response.status === 200) {
          this.response.list = response.data.data.data
          this.response.meta = response.data.data.meta
        }
      }).catch((error) => {
        console.log(error)
        this.snack('warning', '관리자에게 문의 바랍니다.')
      })
    },
    cssSelectorTypeToLabel (type) {
      for (let cssSelectorType of this.config.cssSelectorTypes) {
        if (cssSelectorType.value === type) {
          return cssSelectorType.text
        }
      }
    },
    getPartnerName (partnerName) {
      if (partnerName === null || partnerName === '') {
        return '* 전체 CP 공통'
      } else {
        return partnerName
      }
    },
    /* 페이지네이션 클릭시 */
    paginationClick (page) {
      this.vModel.page = Number(page)
      this.$router.replace({ name: 'partnersCrawlingCssSelectorList', query: this.getXHttpParams() })

      this.cssSelectorList()
    },
    /* 검색시 */
    submitKeyword () {
      this.vModel.page = 1
      this.$router.replace({ name: 'partnersCrawlingCssSelectorList', query: this.getXHttpParams() })

      this.cssSelectorList()
    },
    /**
     * xHTTP 통신을 위한 Params 반환
     *
     * @param chkRouterQueryValue
     * @returns {{page: number, per: number, keyword: string}}
     */
    getXHttpParams (chkRouterQueryValue = false) {
      let params = {
        per: this.vModel.per,
        page: this.vModel.page,
        keyword: this.vModel.keyword,
      }

      if (chkRouterQueryValue) {
        if (this.$route.query.page) {
          this.vModel.page = params.page = Number(this.$route.query.page)
        } else {
          this.vModel.page = params.page = 1
        }

        if (this.$route.query.keyword) {
          this.vModel.keyword = params.keyword = this.$route.query.keyword
        } else {
          this.vModel.keyword = params.keyword = ''
        }
      }

      return params
    },
    moveRegister () {
      this.$router.push({ name: 'partnersCrawlingCssSelectorRegister' })
    },
    tableRowClick (v) {
      this.$router.push({ name: 'partnersCrawlingCssSelectorEdit', params: { filter_keyword_id: v.id } })
    },
  }
}
</script>
